<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="habitacionData === undefined">
      <h4 class="alert-heading">
        Error al obtener los datos de la habitación
      </h4>
      <div class="alert-body">
        No se encontró ninguna habitación con este identificador. Consulte
        <b-link class="alert-link" :to="{ name: 'residencia-ambientes-list' }">
          la lista de habitaciones
        </b-link>
        para ver si hay otras habitaciones.
      </div>
    </b-alert>

    <template v-if="habitacionData">
      <b-card border-variant="primary" no-body>
        <b-card-body>
          <b-row>
            <b-col lg="8">
              <h2 class="mb-0">
                ALBERGUE: {{ habitacionData.residencia.nombre }}
              </h2>
              <h5 class="mb-0">HABITACIÓN: {{ habitacionData.codigo }}</h5>
              <h6 class="text-muted mb-0">
                TIPO: {{ habitacionData.tipo.nombre }}
              </h6>
              <!-- <h6 class="text-muted">
                {{ habitacionData.title }}
              </h6> -->
            </b-col>
            <b-col lg="2" offset-lg="2">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="relief-danger"
                pill
                block
                size="sm"
                @click="$router.push({ name: 'residencia-ambientes-list' })"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  class="mr-50"
                  size="16"
                />
                Regresar
              </b-button>
            </b-col>
          </b-row>

          <hr />
          <label for="res">Lista de residentes activos en la habitación.</label>
          <b-form v-show="false" @submit.prevent="onSubmit">
            <!-- Residentes -->
            <b-row>
              <b-col sm="10" md="10" lg="10">
                <b-form-group
                  label="persona"
                  label-class="text-uppercase font-weight-bold"
                  label-size="sm"
                >
                  <vue-autosuggest
                    ref="autocomplete"
                    v-model="query"
                    :suggestions="suggestions"
                    :input-props="inputProps"
                    :section-configs="sectionConfigs"
                    :render-suggestion="renderSuggestion"
                    :get-suggestion-value="getSuggestionValue"
                    @input="fetchResults"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-alert variant="danger" show class="text-center">
                  <h4 class="alert-heading">
                    Aforo
                  </h4>
                  <div class="alert-body">
                    <span class="font-weight-bold"
                      >{{ habitacionData.aforo_subtotal }} /
                      {{ habitacionData.aforo_total }}</span
                    >
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <!-- Fechas -->
            <b-row>
              <b-col cols="4">
                <b-form-group
                  label="bienes"
                  label-class="text-uppercase font-weight-bold"
                  label-size="sm"
                >
                  <vue-autosuggest
                    ref="inputBienes"
                    v-model="queryBienes"
                    :suggestions="suggestionsBienes"
                    :render-suggestion="renderSuggestionBienes"
                    :get-suggestion-value="getSuggestionValueBienes"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: '',
                      class: 'form-control',
                    }"
                    @selected="setResidenciaSelectBienes"
                    @input="fetchResultsBienes"
                    @focus="fetchResultsBienes"
                  />
                </b-form-group>
              </b-col>
              <!-- Fecha ingreso -->
              <b-col cols="3">
                <b-form-group
                  label="Fecha ingreso"
                  label-class="text-uppercase font-weight-bold"
                  label-size="sm"
                >
                  <flat-pickr
                    v-model="residenteHabitacionData.fecha_ingreso"
                    :config="config"
                    placeholder="dd/mm/yyyy"
                    required
                  />
                </b-form-group>
              </b-col>
              <!-- Fecha salida -->
              <b-col cols="3">
                <b-form-group
                  label="Fecha salida"
                  label-class="text-uppercase font-weight-bold"
                  label-size="sm"
                >
                  <flat-pickr
                    v-model="residenteHabitacionData.fecha_salida"
                    :config="config"
                    placeholder="dd/mm/yyyy"
                  />
                </b-form-group>
              </b-col>
              <!-- Button Submit -->
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 mt-1"
                  size="sm"
                  block
                >
                  <feather-icon icon="SaveIcon" size="16" />
                  Grabar
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="4">
                <b-table
                  class="mt-1"
                  :items="residenteHabitacionData.bienesArray"
                  :fields="fieldsBienes"
                  head-variant="dark"
                  responsive
                  hover
                  small
                >
                  <template #cell(nombre)="row">
                    <span class="text-nowrap">
                      {{ row.item.nombre }}
                    </span>
                  </template>
                  <template #cell(actions)="row">
                    <b-button
                      variant="flat-danger"
                      class="btn-icon"
                      @click="deleteItemBien(row.index)"
                    >
                      <feather-icon icon="Trash2Icon" size="16" />
                    </b-button>
                  </template>
                  <template #cell(cantidad)="data">
                    <b-form-input
                      v-model="data.item.cantidad"
                      type="number"
                      size="sm"
                    />
                  </template>
                </b-table>
              </b-col>
              <b-col cols="8" />
            </b-row>
          </b-form>

          <!-- Tabla de habitacion x residente -->
          <b-table
            ref="TablaResidentes"
            class="relative-position mt-1"
            responsive="sm"
            striped
            hover
            :items="residentes"
            :fields="fields"
            small
            head-variant="dark"
          >
            <!-- Column: User -->
            <template #cell(usuario)="data">
              <h6 class="mb-0">
                {{ data.item.persona.nombre_completo }} -
                <span class="text-muted"
                  >({{
                    data.item.persona.pueblo_indigena &&
                      data.item.persona.pueblo_indigena.nombre
                  }})</span
                >
              </h6>
              <span class="text-muted mb-0 mt-0" style="font-size: 10px;">{{
                data.item.escuela.nombre
              }}</span>
              <br />
              <span class="text-muted mb-0 mt-0" style="font-size: 10px;">
                {{ data.item.persona.sexo_nombre }}</span
              >
            </template>
            <template #row-details="row">
              <b-card>
                <ul>
                  <li
                    v-for="(value, key) in row.item.bienes_activos"
                    :key="key"
                  >
                    0{{ value.pivot.cantidad }} {{ value.nombre }}
                  </li>
                </ul>
              </b-card>
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
                dropleft
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="data.toggleDetails">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Bienes</span>
                </b-dropdown-item>
                <!-- Desasignar beca -->
                <b-dropdown-item
                  v-b-modal.modal-2
                  @click="showDesasignar(data.item)"
                >
                  <feather-icon icon="UserXIcon" />
                  <span class="align-middle ml-50">Desasignar habitación</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click="deleteItem(data.index, data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Eliminar</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
        </b-card-body>

        <desasignar-habitacion-form-modal
          :item-habitacion="itemHabitacion"
          :item-persona="itemPersona"
          @update-table-residentes="actualizarTabla"
        />
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BTable,
  BCard,
  BCardBody,
  BAlert,
  BFormInput,
  BLink,
  BDropdown,
  BDropdownItem,
  // BMedia, BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import {
  ref,
  watch,
  nextTick,
  computed,
  onUnmounted,
} from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueAutosuggest } from "vue-autosuggest";
// import vSelect from 'vue-select'
import axios from "@axios";
import DesasignarHabitacionFormModal from "../components/DesasignarHabitacionFormModal.vue";
import ambienteStoreModule from "./ambienteStoreModule";

export default {
  components: {
    DesasignarHabitacionFormModal,
    VueAutosuggest,
    flatPickr,
    // vSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BTable,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   persona: {
  //     type: Object,
  //     default() {
  //       return { }
  //     },
  //   },
  //   editedItem: {
  //     type: Object,
  //     default() {
  //       return { }
  //     },
  //   },
  // },
  data() {
    return {
      // Props
      itemHabitacion: {},
      itemPersona: {
        persona: {
          nombre_completo: "",
          pueblo_indigena: {
            nombre: "",
          },
          sexo_nombre: "",
        },
        escuela: {
          nombre: "",
        },
      },
      config: {
        // static: true,
        // wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "Y-m-d",
        locale: Spanish, // locale for this instance only
      },
      tipos: [],

      query: "",
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      photosUrl: "/buscar-persona",
      inputProps: {
        id: "autosuggest__input_ajax",
        placeholder: "Ingresar número de indentificación, código ó apellidos?",
        class: "form-control",
        name: "ajax",
      },
      suggestions: [],
      sectionConfigs: {
        personas: {
          limit: 30,
          label: "Alumnos",
          onSelected: (selected) => {
            this.selected = selected.item;
          },
        },
        docentes: {
          limit: 30,
          label: "Docentes",
          onSelected: (selected) => {
            this.selected = selected.item;
          },
        },
      },

      // query: '',
      queryBienes: "",
      // selected: null,
      selectedBienes: null,
      // timeout: null,
      // debounceMilliseconds: 500,
      // suggestions: [],
      suggestionsBienes: [],
      modalidades: [],
      habitacion: {
        aforo_subtotal: 0,
        aforo_total: 0,
      },
      bienesSeleccionados: [
        { cantidad: 2, nombre: "COLCHON" },
        { cantidad: 1, nombre: "JUEGO  DE SABANAS" },
      ],
      bienes: [],
      // residentes: [],
      fieldsBienes: [
        {
          key: "actions",
          label: "*",
        },
        { key: "cantidad", label: "cant." },
        { key: "nombre", label: "nombre" },
      ],
      fields: [
        { key: "usuario", label: "residente" },
        { key: "pivot.fecha_ingreso", label: "F. Ingreso" },
        { key: "pivot.fecha_salida", label: "F. Salida" },
        { key: "actions", label: "Acciones" },
      ],
    };
  },
  created() {
    this.$http.get("becas/modalidades").then((response) => {
      this.modalidades = response.data.data;
    });
    this.$http.get("residencia/habitaciones/tipos").then((response) => {
      this.tipos = response.data.data;
    });
  },
  methods: {
    showDesasignar(item) {
      this.itemPersona = item;
      const { habitaciones } = item;
      const [habitacion] = habitaciones.filter((i) => i.pivot.estado === 1);
      this.itemHabitacion = habitacion;
    },
    actualizarTabla() {
      this.getResidentes();
      // this.$refs.refTablaResidentes.refresh()
    },
    deleteItemBien(index) {
      this.residenteHabitacionData.bienesArray.splice(index, 1);
    },
    deleteItem(index, item) {
      if (
        this.residenteHabitacionData.becario_id === item.pivot.becario_id &&
        this.residenteHabitacionData.usuario_id === item.pivot.usuario_id
      ) {
        this.$http
          .delete(`habitaciones/${item.pivot.id}/residentes-habitacion`)
          .then(() => {
            this.residentes.splice(index, 1);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Eliminado correctamente!",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "No se puede eliminar!!",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "No se puede eliminar!",
            text:
              "No se puede eliminar porque no le corresponde al residente!!",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    // Habitacion
    setResidenciaSelect(obj) {
      this.habitacion = obj.item;
      // hacer la peticion para saber que personas estan en la habitación
      this.$http
        .get(`habitaciones/${obj.item.id}/residentes`)
        .then((response) => {
          this.residentes = response.data.data;
        });

      this.residenteHabitacionData.habitacion_id = obj.item.id;
    },
    setTipoHabitacionSelect(id) {
      this.residenteHabitacionData.tipo_id = id;
    },
    fetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const personasPromise = this.$http.get(`${this.photosUrl}/${query}`);

        Promise.all([personasPromise])
          .then((values) => {
            this.suggestions = [];
            this.selected = null;

            const personas = values[0].data.data[0];
            const docentes = values[0].data.data[1];
            // eslint-disable-next-line no-unused-expressions
            personas.length &&
              this.suggestions.push({ name: "personas", data: personas });
            // eslint-disable-next-line no-unused-expressions
            docentes.length &&
              this.suggestions.push({ name: "docentes", data: docentes });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.error}`,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      }, this.debounceMilliseconds);
    },
    renderSuggestion(suggestion) {
      return (
        <div>
          {suggestion.item.apellido_paterno} {suggestion.item.apellido_materno},{" "}
          {suggestion.item.nombres}
        </div>
      );
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion;
      return `${item.apellido_paterno} ${item.apellido_materno}, ${item.nombres}`;
    },
    // Bienes
    setResidenciaSelectBienes(obj) {
      const { item } = obj;
      const { id, nombre } = item;
      const dato = {
        bien_id: id,
        nombre,
        becario_id: this.residenteHabitacionData.becario_id,
        usuario_id: this.residenteHabitacionData.usuario_id,
        cantidad: 1,
      };

      const flag = this.residenteHabitacionData.bienesArray.some(
        (i) => i.bien_id === id
      );

      if (!flag) {
        // this.bienesSeleccionados.push(dato)
        this.residenteHabitacionData.bienesArray.push(dato);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Agregado correctamente!!",
            icon: "EditIcon",
            variant: "success",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ya se agregó",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    setTipoHabitacionSelectBienes(id) {
      this.residenteHabitacionData.tipo_id = id;
    },
    renderSuggestionBienes(suggestion) {
      return <div class="d-flex">{suggestion.item.nombre}</div>;
    },
    getSuggestionValueBienes(suggestion) {
      return `${suggestion.item.nombre.toUpperCase()}`;
    },
    fetchResultsBienes() {
      const { queryBienes } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const promise = this.$http.get(`bienes?filter=${queryBienes}`);
        // Must return a promise that resolves to an array of items
        return promise.then((response) => {
          this.suggestionsBienes = [];
          this.selectedBienes = null;
          // Pluck the array of items off our axios response
          // const { items } = response.data
          // this.suggestions = response.data.data
          this.suggestionsBienes = [{ data: response.data.data }];
          // Must return an array of items or an empty array if an error occurred
          return this.suggestionsBienes || [];
        });
      }, this.debounceMilliseconds);
    },
  },
  setup(props, { emit }) {
    const RESIDENCIA_APP_STORE_MODULE_NAME = "habitaciones";

    // Register module
    if (!store.hasModule(RESIDENCIA_APP_STORE_MODULE_NAME))
      store.registerModule(
        RESIDENCIA_APP_STORE_MODULE_NAME,
        ambienteStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESIDENCIA_APP_STORE_MODULE_NAME))
        store.unregisterModule(RESIDENCIA_APP_STORE_MODULE_NAME);
    });

    // const { editedItem } = toRefs(props)
    const editedIndex = ref(-1);
    const titleButton = ref("+");
    const dialog = ref(true);
    const toast = useToast();
    const habitacionData = ref(null);
    const restData = ref(null);
    const residentes = ref([]);
    const form = {
      becario_id: null,
      usuario_id: null,
      habitacion_id: null,
      fecha_ingreso: null,
      bienesArray: [],
    };

    const residenteHabitacionData = ref(JSON.parse(JSON.stringify(form)));

    const resetuserData = () => {
      residenteHabitacionData.value = JSON.parse(JSON.stringify(form));
      residenteHabitacionData.value.becario_id = restData.value.id;
      residenteHabitacionData.value.usuario_id = restData.value.usuario_id;
    };

    const getResidentes = () => {
      axios
        .get(`habitaciones/${router.currentRoute.params.id}/residentes`)
        .then((response) => {
          residentes.value = response.data.data;
        });
    };

    if (router.currentRoute.params.id) {
      // Obtener al becario
      store
        .dispatch("habitaciones/fetchHabitacion", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          habitacionData.value = response.data;
          habitacionData.value.title = `${habitacionData.value.residencia.nombre} - HABITACIÓN ${habitacionData.value.codigo} - SOLO ${habitacionData.value.tipo.nombre}`;

          // Llamaar para rellenar los residentes que ocupan esta habitacion
          getResidentes();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            habitacionData.value = undefined;
          }
        });
    }

    const close = () => {
      dialog.value = false;
      nextTick(() => {
        resetuserData();
        editedIndex.value = -1;
      });
    };

    const formTitle = computed(() =>
      editedIndex.value === -1
        ? "RESDIENTE: CHUMBE KAJEKUI, VIRGILIO"
        : `Editar Asignacion de residente N° ${editedIndex.value}`
    );

    watch(dialog, () => {
      // eslint-disable-next-line no-unused-expressions
      dialog.value || close();
    });

    // watch(editedItem, () => {

    // })
    const resolveUserServicioVariant = (val) => {
      if (val.codigo === "01-medicina") return "primary";
      if (val.codigo === "02-odontologia") return "secondary";
      if (val.codigo === "03-oftamologia") return "success";
      if (val.codigo === "04-topico") return "danger";
      if (val.codigo === "05-gineco-obstetrico") return "obstetricia-color";
      if (val.codigo === "06-psicologia") return "psicologia-color";
      if (val.codigo === "07-enfermeria") return "info";
      return "primary";
    };

    const onSubmit = () => {
      if (editedIndex.value > -1) {
        // vamos a editar

        store
          .dispatch(
            "habitaciones/updatedHabitacion",
            residenteHabitacionData.value
          )
          .then(() => {
            // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: "¡Actualización exitosa",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            dialog.value = false;
            emit("refresh-data-habitaciones-table");
          })
          .catch(() => {});
      } else {
        // Eliminar el campo nombre del array de bienes seleccionados.
        const arr = residenteHabitacionData.value.bienesArray.map((item) => ({
          becario_id: item.becario_id,
          bien_id: item.bien_id,
          cantidad: item.cantidad,
          usuario_id: item.usuario_id,
        }));
        residenteHabitacionData.value.bienesArray2 = arr;
        store
          .dispatch(
            "residentes/addResidenteHabitacion",
            residenteHabitacionData.value
          )
          .then(() => {
            // Clean form, Close Modal y Refresh Table
            toast({
              component: ToastificationContent,
              props: {
                title: "¡Registro exitoso!",
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            resetuserData();
            dialog.value = false;
            emit("refresh-data-habitaciones-table");
          })
          .catch((error) => {
            if (error.response.status === 409) {
              toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.error}`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };

    return {
      residenteHabitacionData,
      resetuserData,
      onSubmit,
      residentes,
      close,
      dialog,
      formTitle,
      titleButton,
      habitacionData,
      resolveUserServicioVariant,
      avatarText,
      restData,
      getResidentes,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
/* Number Type Input Box Scss for - Remove arrow on hover */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: auto !important;
}
</style>
