<template>
  <div>
    <b-modal
      id="modal-2"
      ref="modal"
      :header-bg-variant="'warning'"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            Registro de salida de habitacón
          </strong>
        </div>

      </template>
      <h4 class="mb-0">
        RESIDENTE: {{ itemPersona.persona.nombre_completo }}
      </h4>
      <h6 class="mb-0">
        ESCUELA: {{ itemPersona.escuela.nombre }}
      </h6>
      <h6 class="text-muted mb-0">
        PUEBLO INDIGENA: {{ itemPersona.persona.pueblo_indigena.nombre }}
      </h6>
      <h6 class="text-muted">
        SEXO: {{ itemPersona.persona.sexo_nombre }}
      </h6>
      <h6>{{ itemHabitacion.codigo }}</h6>
      <!-- </b-col> -->

      <form
        ref="formDesasignarModal"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label="fecha de salida"
          label-class="text-uppercase"
          label-size="sm"
          invalid-feedback="Fecha es obligatorio"
          :state="fechaSalidaState"
        > <b-form-input
          v-model="fecha_salida"
          type="datetime-local"
          :state="fechaSalidaState"
          required
        />
        </b-form-group>

        <b-form-group
          label="descripción del motivo"
          label-class="text-uppercase"
          label-size="sm"
          invalid-feedback="Motivo es obligatorio y no debe superar los 250 caracteres."
          :state="motivoRetiroState"
        >
          <b-form-textarea
            id="textarea"
            v-model="motivo_retiro"
            placeholder="Descripción del motivo..."
            :state="motivoRetiroState || motivo_retiro.length <= 250"
            required
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormTextarea,
  BFormInput,
  // BCol,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    // BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // vSelect,
  },
  props: {
    itemHabitacion: {
      type: Object,
      default() {
        return {}
      },
    },
    itemPersona: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      fecha_salida: null,
      fechaSalidaState: null,
      motivoId: null,
      motivo_retiro: '',
      motivoRetiroState: null,
      submittedNames: [],
      motivos: ['REGULAR', 'FALTAS', 'PARA SER TRASLADO', 'PERDIDA DE LA BECA', 'INCUMPLIMIENTO DE DEBERES'],
    }
  },
  methods: {

    checkFormValidity() {
      const valid = this.$refs.formDesasignarModal.checkValidity()

      // Darle el resultado a todos los otros campos el valor de valid (true o false).
      this.fechaSalidaState = valid
      this.motivoId = valid
      this.motivoRetiroState = valid
      return valid
    },
    resetModal() {
      this.fecha_salida = null
      this.fechaSalidaState = null
      this.motivo_retiro = ''
      this.motivoRetiroState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      const { id, habitacion_id } = this.itemHabitacion

      const form = {
        habitacion_id,
        fecha_salida: this.fecha_salida,
        motivo_retiro: this.motivo_retiro,
      }
      this.$http.put(`/residencias/desasignar-habitacion/${id}`, form)
        .then(() => {
          // lanzar mensajito y actulizar la tabla
          this.$nextTick(() => {
            this.$bvModal.hide('modal-2')
          })
          this.$emit('update-table-residentes')
        })
    },
  },
}
</script>
